import { Select, InputLabel, MenuItem } from '@mui/material';
import { min } from 'moment';
import React, { useState } from 'react';

const styles = {
  formContainer: {
    maxWidth: '100%',
    margin: '40px auto',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
    backgroundColor: '#ffffff',
  },
  title: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '30px',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  inputGroup: {
    marginBottom: '20px',
    display: 'flex',
    gap: 10
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    color: '#555',
    fontSize: '14px',
    fontWeight: '500',
  },
  input: {
    width: '100%',
    padding: '10px 12px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '16px',
    transition: 'border-color 0.3s ease',
    boxSizing: 'border-box',
  },
  button: {
    width: '100%',
    padding: '12px',
    backgroundColor: '#0056b3',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
};

const EmailForm = ({ userData, handleChange, setFormStep }) => {

  

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(userData);
    setFormStep(1);
    // Aquí iría la lógica para enviar los datos del formulario
  };

  return (
    <div style={{
      backgroundColor: '#f5f5f5',
      width: '100%',
    }}>
      <div className="container" >
        <div className="row">
          <div className='col-lg-12 col-md-12' style={{
            padding: '50px 0',
            paddingBottom: 10
          }}>
            <div className="title-block mx-auto">
              <div className="section_title mb-4 text-center">
                <h2><span>Formulario para citas</span></h2>

              </div>
            </div>
          </div>
          <div style={styles.formContainer} className='col-lg-6 col-md-12'>

            <p className="section_subtitle mx-auto" style={{
              width: '100%',
              padding: '0px 0px 20px 0px',
            }}>
              Por favor, introduzca los datos necesarios para validar su cita previa.
            </p>
            <form onSubmit={handleSubmit}>
              <div style={styles.inputGroup}>
                <div style={{
                  width: '100%',

                }}>
                  <label htmlFor="nombre" style={styles.label}>Nombre Completo</label>
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    value={userData.nombre}
                    onChange={handleChange}
                    required
                    style={styles.input}
                  />
                </div>

              </div>

              <div style={styles.inputGroup}>
                <div style={{
                  width: '100%',

                }}>
                  <label htmlFor="email" style={styles.label}>Correo Electrónico</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={userData.email}
                    onChange={handleChange}
                    required
                    style={styles.input}
                  />
                </div>
              </div>
              <div style={styles.inputGroup}>
                <div style={{
                  width: '50%',

                }}><label htmlFor="cedula" style={styles.label}>Cédula</label>
                  <input
                    type="text"
                    id="cedula"
                    name="cedula"
                    value={userData.cedula}
                    onChange={handleChange}
                    required
                    style={styles.input}
                  />
                </div>
                <div style={{
                  width: '50%',

                }}>
                  <label htmlFor="fechaRetiro" style={styles.label}>Fecha de Retiro</label>
                  <input
                    type="date"
                    id="fechaRetiro"
                    name="fechaRetiro"
                    value={userData.fechaRetiro}
                    onChange={handleChange}
                    required
                    style={styles.input}
                  />
                </div>
              </div>
              <div style={styles.inputGroup}>

                <div style={{
                  width: '100%',

                }}>
                  <label htmlFor="nroGestion" style={styles.label}>Número de Gestión Empresarial</label>
                  <input
                    type="text"
                    id="nroGestion"
                    name="nroGestion"
                    value={userData.nroGestion}
                    onChange={handleChange}
                    required
                    style={styles.input}
                  />
                </div>
              </div>
              <button
                type="submit"
                style={styles.button}
              >
                Enviar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailForm;