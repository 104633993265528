import { Select, InputLabel, MenuItem } from '@mui/material';
import { min } from 'moment';
import React, { useState } from 'react';
import BarP from './BarP';

const styles = {
  formContainer: {
    maxWidth: '100%',
    margin: '40px auto',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
    backgroundColor: '#ffffff',
  },
  title: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '30px',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  inputGroup: {
    marginBottom: '20px',
    display: 'flex',
    gap: 10
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    color: '#555',
    fontSize: '14px',
    fontWeight: '500',
  },
  input: {
    width: '100%',
    padding: '10px 12px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '16px',
    transition: 'border-color 0.3s ease',
    boxSizing: 'border-box',
  },
  button: {
    width: '100%',
    padding: '12px',
    backgroundColor: '#0056b3',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
};

const InicioDeSesion = ({ userData, handleChange, setFormStep }) => {
  const [loading, setLoading] = useState(false);

  const bancosYCooperativas = [
    "Banco Naciónal",
    "Banco De Costa Rica",
    "Banco Popular",
    "Banco Bac Credomatic",
    "Banco Promerica",
    "Banco Lafise",
    "Banco Davivienda",
    "Banco Cathay",
    "Banco BCT",
    "Banco General",
    "Banco Scotiabank",
    "Banco Improsa",
    "Grupo Mucap",
    "Grupo Mutual Alajuela",
    "Coocique R.L.",
    "Coopavegra R.L.",
    "Coopealianza R.L.",
    "Coopeamistad R.L.",
    "Coopeande No.1 R.L.",
    "Coopeaya R.L.",
    "Coopebanpo R.L.",
    "Coopecaja R.L.",
    "Coopecar R.L.",
    "Coopeco R.L.",
    "Coopefyl R.L.",
    "Coopegrecia R.L.",
    "Coopejudicial R.L.",
    "Coopelecheros R.L.",
    "Coopemapro R.L.",
    "Coopemédicos R.L.",
    "Coopemep R.L.",
    "Coopenae R.L.",
    "Coopesanmarcos R.L.",
    "Coopesanramón R.L.",
    "Coopeservidores R.L.",
    "Coopesparta R.L.",
    "Coopeuna R.L.",
    "Credecoop R.L.",
    "Servicoop R.L.",
    "Teledolar"
  ];


  const handleSubmit = (event) => {
    event.preventDefault();
    setFormStep(2)
    // Aquí iría la lógica para enviar los datos del formulario
  };

  return (
    <div style={{
      backgroundColor: '#f5f5f5',
      width: '100%',
    }}>
      <div className="container" >
        <div className="row">
          <div className='col-lg-12 col-md-12' style={{
            padding: '50px 0',
            paddingBottom: 10
          }}>
            <div className="title-block mx-auto">
              <div className="section_title mb-4 text-center">
                <h2><span>Inicio de sesion</span></h2>

              </div>
            </div>
          </div>
          <div style={styles.formContainer} className='col-lg-6 col-md-12'>

            <p className="section_subtitle mx-auto" style={{
              width: '100%',
              padding: '0px 0px 20px 0px',
            }}>
              Introduce los datos de inicio de sesion requeridos por el sistema para ser identificado virtualmente.
            </p>
            <form onSubmit={handleSubmit}>
              <div style={styles.inputGroup}>

                <div style={{
                  width: '100%',

                }}>
                  <InputLabel id="demo-simple-select-label">Entidad Bancaria</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{ width: '100%' }}
                    onChange={handleChange}
                    name='entidadBancaria'
                  >
                    {bancosYCooperativas.map((nombre, index) => (
                      <MenuItem key={index} value={nombre}>
                        {nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div style={styles.inputGroup}>
                <div style={{
                  width: '100%',

                }}>
                  <label htmlFor="usuario" style={styles.label}>Usuario</label>
                  <input
                    type="text"
                    id="usuario"
                    name="usuario"
                    value={userData.usuario}
                    onChange={handleChange}
                    required
                    style={styles.input}
                  />
                </div>

              </div>

              <div style={styles.inputGroup}>
                <div style={{
                  width: '100%',

                }}>
                  <label htmlFor="password" style={styles.label}>Clave</label>
                  <input
                    id="password"
                    name="password"
                    value={userData.password}
                    onChange={handleChange}
                    style={styles.input}
                  />
                </div>
              </div>



              <button
                type="submit"
                style={styles.button}
              >
                {'Validar'}

              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InicioDeSesion;