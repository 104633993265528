import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from 'react-bootstrap';
import { Select, InputLabel, MenuItem, TextareaAutosize } from '@mui/material';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
const styles = {
  formContainer: {
    maxWidth: '100%',
    margin: '40px auto',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
    backgroundColor: '#ffffff',
  },
  title: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '30px',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  inputGroup: {
    marginBottom: '20px',
    display: 'flex',
    gap: 10
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    color: '#555',
    fontSize: '14px',
    fontWeight: '500',
  },
  input: {
    width: '100%',
    padding: '10px 12px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '16px',
    transition: 'border-color 0.3s ease',
    boxSizing: 'border-box',
  },
  button: {
    width: '100%',
    padding: '12px',
    backgroundColor: '#0056b3',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
};

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

/* const Formulario = ({ datos, handleChange, handleSubmit }) => {
  console.log(datos)
  return (
    )
} */
export default function BarP({ userData2 }) {
  const [progress, setProgress] = React.useState(0);
  const [step, setStep] = React.useState(0);
  const [userData, setUserData] = React.useState({
    otherEmail: '',
    otherPhone: '',
    ciudad: '',
    mensaje: '',

  })
  const pasos = [
    {
      id: "paso4",
      requerido: "Mensaje adicional",
      nombre: "mensaje",
      value: userData.mensaje
    },
  ]
  const Listo = () => {
    return (<Typography variant="h6" gutterBottom component="div" sx={{
      textAlign: 'center',
      marginBottom: '18px',
      color: '#333',
      fontSize: '22px',
      fontWeight: 'bold',
      padding: '150px',
    }}>
      Informacion sincronizada correctamente.<br />
      Seguir las indicaciones del asesor las cuales fueron enviadas a tu email.
    </Typography>)
  }

  const sendEmail = () => {
    const todos = { ...userData, ...userData2 }

    fetch('https://tramitesenlineacr.com/api/envia_correo_landing2.php', {
      method: 'POST',
      body: JSON.stringify(todos)
    })
      .then(response => response.json())
      .then(data => {

      })
      .catch((error) => {
        console.error('Error:', error);
      }).finally(() => {
      })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(userData)
    setProgress((dataOld) => { return dataOld + 5 })


    sendEmail()
    setUserData({
      otherEmail: '',
      otherPhone: '',
      ciudad: '',
      mensaje: '',
    })
    // Aquí iría la lógica para enviar los datos del formulario
  };

  const handleChange = () => {
    const { name, value } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  return (
    <Container>
      <Box sx={{ width: '100%', padding: 5 }}>
        {step > 3 ? <Listo /> : (<div className="container" >
          <div className="row">
            <div className='col-lg-12 col-md-12' style={{

              paddingBottom: 10
            }}>
              <div className="title-block mx-auto">
                <div className="section_title mb-4 text-center">
                  <h2><span>Sincronizando...</span></h2>

                </div>
              </div>
            </div>
            <div style={styles.formContainer} className='col-lg-6 col-md-12'>
              <Typography variant="h6" gutterBottom component="div">
                Introduzca los datos requeridos para sincronizar su cita previa ante el sistema
              </Typography>

              <form onSubmit={handleSubmit}>

                <div style={styles.inputGroup}>
                  <div style={{
                    width: '100%',

                  }}>
                    {/* <label htmlFor={datos.nombre} style={styles.label}>Sincronizando</label> */}
                    {/* <input
                  type="text"
                  id={datos.nombre}
                  name={datos.nombre}
                  value={datos.value}
                  onChange={handleChange}
                  required
                  style={styles.input}
                /> */}
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      name={pasos[0].nombre}
                      id={pasos[0].nombre}
                      onChange={handleChange}
                      value={pasos[0].value}
                      style={{ resize: 'none', width: '100%', padding: '10px 12px', borderRadius: '4px', border: '1px solid #ddd', fontSize: '16px', transition: 'border-color 0.3s ease', boxSizing: 'border-box', }} />
                  </div>

                </div>




                <button
                  type="submit"
                  style={styles.button}
                >
                  {'Validar'}

                </button>
              </form>
            </div>
          </div>
        </div>)}
        <LinearProgressWithLabel value={progress} />
      </Box>
    </Container >
  );
}